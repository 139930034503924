<!-- 课程列表 -->
<template>
  <div class="flex-col space-y-20 page">
    <div style="display: flex;width: 100%;align-items: center;">
      <div style="background-color: #0074ff;color: #fff;text-align: center;padding: 2rem 4rem;">
        <div style="font-size: 1.2rem;margin-bottom: 1rem;">缴费成功</div>
        <div style="font-size: 1.5rem;">￥{{data.price}}</div>
        <div style="display: flex;margin-top: 2rem;">
          <div
            style="padding: 0 1rem;border: 1px solid #fff;border-radius: 5px;line-height:2; cursor: pointer;margin-right: 1rem;"
            @click="getorderinvoiceDetail()">查看开票信息</div>
          <div
            style="padding: 0 1rem;border: 1px solid #fff;border-radius: 5px;line-height:2; cursor: pointer;background-color: #fff;color: #0074ff;"
            @click="getCurriculmDetail()" v-if="identity == 0">查看课程详情</div>
			<div
			  style="padding: 0 1rem;border: 1px solid #fff;border-radius: 5px;line-height:2; cursor: pointer;background-color: #fff;color: #0074ff;"
			  @click="getToCurriculm()" v-else>查看更多课程</div>
        </div>
        <div style="margin-top: 2rem; font-size: 0.7rem;">课程缴费成功，请尽快开始学习完成培训，获取证书</div>
      </div>
      <div>
        <div style="margin-left:4rem; font-size: 0.7rem; color; #7D7D89; line-height:2rem;">
          <div>课程名称：{{data.curriculum_name}}</div>
          <div>培训方式：{{data.train_type_name}}</div>
          <div>课程类型：{{data.curriculum_cate_name}}</div>
          <div>报名时间：{{data.create_time}}</div>
          <div>付款时间：{{data.pay_time}}</div>
          <div>订单编号：{{data.ordersn}}</div>
        </div>
      </div>
    </div>

    <div class="flex-col space-y-30 section_3">
      <div class="flex-col group_3">
        <div style="text-align: center;font-size: 1rem;color: #3D3D3D;">--为你推荐--</div>
        <div class="flex-col space-y-15 group_5">
          <el-table :data="list" style="width: auto;font-size: 0.4rem;" :header-cell-style="{background:'#eef0f7bf'}"
            v-loading="loading" element-loading-text="数据正在加载中" element-loading-spinner="el-icon-loading">
            <el-table-column prop="curriculum_id" label="序号" width="auto" align="center"></el-table-column>
            <el-table-column prop="name" label="课程名称" width="auto" align="center"></el-table-column>
            <el-table-column prop="curriculum_cate_name" label="课程类型" width="auto" align="center"></el-table-column>
            <el-table-column prop="train_type_name" label="培训方式" width="auto" align="center"></el-table-column>
            <el-table-column prop="train_object_name" label="培训对象" width="auto" align="center"></el-table-column>
            <el-table-column prop="credit_hours" label="学时" width="auto" align="center"></el-table-column>
            <el-table-column prop="charging_standard" label="收费标准" width="auto" align="center"></el-table-column>
            <el-table-column prop="end_time" label="缴费截止日期" width="auto" align="center"></el-table-column>
            <el-table-column prop="create_time" label="发布日期" width="auto" align="center"></el-table-column>
            <el-table-column prop="zip" label="操作" width="auto" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleEdit(scope.$index, scope.row)">报名</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
        trainObjectArr: [],
        trainObject: '',
        curriculumCateArr: [],
        curriculumCate: '',
        trainTypeArr: [],
        trainType: '',
        keyword: '',
        list: [],
        data: [], //订单详情
        orderInvoiceId: '',
				identity:sessionStorage.getItem('identity')
      };
    },
    created() {
      let that = this;
      that.getTrainObject();
      that.getCurriculumCate();
      that.getTrainType();
      that.getCurriculumLists();
      // 获取订单详情
			if(that.identity == 1){
				that.getQyOrderDetail();
			}else{
				that.getOrderDetail();
			}
      
    },
    methods: {
      //获取培训对象
      getTrainObject() {
        let that = this;
        that.$request.getTrainObject().then((res) => {
          if (res.code == 200) {
            that.trainObjectArr = res.datas;
          }
        });
      },
      //获取订单详情
      getOrderDetail() {
        let that = this;
        let ordersn = that.$route.query.ordersn;
        that.ordersn = ordersn;
        let param = {
          ordersn,
        };
        that.$request.getOrderDetail(param).then((res) => {
          if (res.code == 200) {
            that.data = res.datas;
            that.pay_status = res.datas.pay_status;
            that.price = res.datas.price;
          } else {
            that.$myMessage.error(res.msg);
          }
        });
      },
		//获取订单详情
		getQyOrderDetail() {
		  let that = this;
		  let ordersn = that.$route.query.ordersn;
		  that.ordersn = ordersn;
		  let param = {
			ordersn,
		  };
		  that.$request.getQyOrderDetail(param).then((res) => {
			if (res.code == 200) {
			  that.data = res.datas.qyorder;
			  that.pay_status = res.datas.qyorder.pay_status;
			  that.price = res.datas.qyorder.price;
			} else {
			  that.$myMessage.error(res.msg);
			}
		  });
		},
      //获取课程类型
      getCurriculumCate() {
        let that = this;
        that.$request.getCurriculumCate().then((res) => {
          if (res.code == 200) {
            that.curriculumCateArr = res.datas;
          }
        });
      },
      // 查看发票详情
      getorderinvoiceDetail() {
        let that = this;
        let orderInvoiceId = that.data.order_invoice_id;
        that.$router.push({
          path: '/user/invoice/info',
          query: {
            order_invoice_id: orderInvoiceId
          }
        });
      },
      //获取培训类型
      getTrainType() {
        let that = this;
        that.$request.getTrainType().then((res) => {
          if (res.code == 200) {
            that.trainTypeArr = res.datas;
          }
        });
      },
      getCurriculmDetail() {
        let that = this;
        //console.log(index, row);
        let curriculumId = that.data.curriculum_id;
        that.$router.push({
          path: '/user/curriculum/detail',
          query: {
            curriculum_id: curriculumId
          }
        });
      },
	  getToCurriculm(){
		  let that = this;
		  that.$router.push({
		    name: 'userCurriculum',
		  });
	  },
      handleCurrentChange(val) {
        let that = this;
        that.page = val;
        that.getCurriculumLists();
      },
      //获取课程列表
      getCurriculumLists() {
        let that = this;
        //that.$store.commit('showLoading')
        that.loading = true;
        let param = {
          page: that.page,
          limit: that.limit,
          train_object_id: that.trainObject,
          curriculum_cate_id: that.curriculumCate,
          train_type_id: that.trainType,
          keyword: that.keyword,
        };
        that.$request.getCurriculumLists(param).then((res) => {
          if (res.code == 200) {
            that.list = res.datas;
            that.page = that.page + 1;
            that.totalpage = res.total_page;
            that.count = res.count;
            //that.$store.commit('hideLoading')
            that.loading = false;
          }
        });
      },
      //课程报名,跳转课程详情
      handleEdit(index, row) {
        let that = this;
        //console.log(index, row);
        let curriculumId = row.curriculum_id;
        that.$router.push({
          path: '/user/curriculum/detail',
          query: {
            curriculum_id: curriculumId
          }
        });
      },

    },
  };
</script>

<style scoped lang="css">
  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .page {
    padding-bottom: 15.56rem;
    background-color: #f5f6fa;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
    flex: 1 1 auto;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .space-y-30>*:not(:first-child) {
    margin-top: 1.88rem;
  }

  .section_3 {
    margin-right: 1.25rem;
    padding: 0 0.94rem 2.19rem;
    /* align-self: flex-end; */

    background-color: #ffffff;
    border-radius: 0.63rem;
    /* width: 71.56rem; */
  }

  .group_3 {
    padding-top: 0.94rem;
  }

  .group_4 {
    margin-right: 0.31rem;
    overflow: hidden;
    border-bottom: solid 0.016rem #c0c4cc;
  }

  .text-wrapper {
    padding: 0.22rem 0 1.28rem;
    border-bottom: solid 0.16rem #0074ff;
  }

  .text_2 {
    color: #0074ff;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .space-y-15>*:not(:first-child) {
    margin-top: 0.94rem;
  }

  .group_5 {
    padding: 1rem 0 0.94rem;
  }

  .group_6 {
    align-self: flex-start;
    overflow: hidden;
    width: 100%;
  }

  .space-x-8>*:not(:first-child) {
    margin-left: 0.5rem;
  }

  .group_7 {
    overflow: hidden;
    margin-right: 1rem;
    /* width: 12.63rem;
    height: 2.19rem; */
  }

  .text_3 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-45>*:not(:first-child) {
    margin-left: 2.81rem;
  }

  .section_4 {
    flex-shrink: 0;
    padding: 0.59rem 0.5rem 0.66rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_4 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    margin-right: 0.16rem;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .group_8 {
    margin-left: 0.94rem;
    overflow: hidden;
    width: 10.69rem;
    height: 2.19rem;
  }

  .text_5 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-14>*:not(:first-child) {
    margin-left: 0.88rem;
  }

  .text_7 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_11 {
    margin-left: 1rem;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_1 {
    margin-left: 0.53rem;
    padding: 0.59rem 0 0.72rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_12 {
    margin-left: 0.5rem;
    margin-right: 0.56rem;
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_2 {
    margin-left: 0.94rem;
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_13 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_3 {
    margin-left: 0.91rem;
    padding: 0.63rem 0 0.72rem;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
    cursor: pointer;
  }

  .text_14 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .section_5 {
    margin-right: 0.31rem;
    height: 27.5rem;
    border: solid 0.031rem #ebeef5;
    position: relative;
  }

  .text_15 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.47rem;
    position: absolute;
    left: 1.53rem;
    top: 3.44rem;
  }

  .text_16 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 3.28rem;
  }

  .group_9 {
    width: 69.38rem;
    height: 27.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  .section_6 {
    background-color: #e9edf380;
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    left: 16.78rem;
    top: 0;
  }

  .section_7 {
    background-color: #ebeef5;
    width: 0.031rem;
    height: 27.5rem;
    position: absolute;
    right: 4.25rem;
    top: 0;
  }

  .image_10 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    left: 23.16rem;
    bottom: 0;
  }

  .image_11 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    left: 30.22rem;
    bottom: 0;
  }

  .image_12 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 32.03rem;
    bottom: 0;
  }

  .image_13 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 26.97rem;
    bottom: 0;
  }

  .image_14 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 20.34rem;
    bottom: 0;
  }

  .image_15 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 12.28rem;
    bottom: 0;
  }

  .section_8 {
    padding: 0.81rem 0.97rem 0.88rem;
    background-image: linear-gradient(180deg, #eef0f7bf 0%, #eef0f7 100%);
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  .space-x-29>*:not(:first-child) {
    margin-left: 1.81rem;
  }

  .text_17 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_18 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-53>*:not(:first-child) {
    margin-left: 3.31rem;
  }

  .group_11 {
    margin-right: 0.25rem;
  }

  .text_19 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_20 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_21 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_22 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_23 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_24 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_25 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_26 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-123>*:not(:first-child) {
    margin-left: 7.69rem;
  }

  .group_12 {
    padding: 0.25rem 1.34rem 0.91rem 1.59rem;
    overflow: hidden;
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 15.63rem;
    border-bottom: solid 0.016rem #ebeef5;
  }

  .space-x-37>*:not(:first-child) {
    margin-left: 2.31rem;
  }

  .el-table .el-table__cell {
    padding: 5px 0 !important;
  }

  .text_27 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_28 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .space-x-83>*:not(:first-child) {
    margin-left: 5.19rem;
  }

  .group_14 {
    flex: 1 1 auto;
    width: 50rem;
  }

  .space-x-76>*:not(:first-child) {
    margin-left: 4.75rem;
  }

  .text_29 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text_31 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_16 {
    flex: 1 1 auto;
    width: 35.5rem;
  }

  .text_33 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text_35 {
    margin: 0.41rem 0 0.22rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
  }

  .text_37 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
  }

  .text_39 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .space-x-55>*:not(:first-child) {
    margin-left: 3.44rem;
  }

  .text_41 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_43 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .divider {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 15rem;
  }

  .divider_1 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 12.5rem;
  }

  .group_18 {
    padding: 0.25rem 1.34rem 0.91rem 1.59rem;
    overflow: hidden;
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.13rem;
    border-bottom: solid 0.016rem #ebeef5;
  }

  .text_45 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_46 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_22 {
    padding: 0.25rem 0 0.31rem 0.13rem;
    overflow: hidden;
    width: 66.59rem;
    position: absolute;
    left: 1.41rem;
    right: 1.38rem;
    top: 25.63rem;
  }

  .space-x-33>*:not(:first-child) {
    margin-left: 2.06rem;
  }

  .text_55 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_56 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_24 {
    margin-left: 7.69rem;
  }

  .text_57 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text_58 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .view_9 {
    margin-left: 5.19rem;
  }

  .text_59 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text_60 {
    margin: 0.41rem 0 0.22rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
  }

  .text_61 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
  }

  .text_62 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_63 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_64 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_26 {
    padding: 0.25rem 1.34rem 0.91rem 1.69rem;
    overflow: hidden;
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 23.13rem;
    border-bottom: solid 0.016rem #ebeef5;
  }

  .space-x-36>*:not(:first-child) {
    margin-left: 2.25rem;
  }

  .text_65 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }

  .text_66 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .divider_2 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 22.5rem;
  }

  .divider_3 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 20rem;
  }

  .divider_4 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 7.5rem;
  }

  .divider_5 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 5rem;
  }

  .text_75 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 3.28rem;
  }

  .text_76 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 3.28rem;
  }

  .text_77 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 3.28rem;
  }

  .text_78 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 3.69rem;
  }

  .text_79 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 3.44rem;
  }

  .text_80 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 3.41rem;
  }

  .text_81 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 3.41rem;
  }

  .text_82 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 3.28rem;
  }

  .text_83 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    left: 1.5rem;
    top: 5.91rem;
  }

  .text_84 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 5.78rem;
  }

  .text_85 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 5.78rem;
  }

  .text_86 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 5.78rem;
  }

  .text_87 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 5.78rem;
  }

  .text_88 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 6.19rem;
  }

  .text_89 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 5.94rem;
  }

  .text_90 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 5.91rem;
  }

  .text_91 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 5.91rem;
  }

  .text_92 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 5.78rem;
  }

  .text_93 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.47rem;
    position: absolute;
    left: 1.47rem;
    top: 10.94rem;
  }

  .text_94 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 10.78rem;
  }

  .text_95 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 10.78rem;
  }

  .text_96 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 10.78rem;
  }

  .text_97 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 10.78rem;
  }

  .text_98 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 11.19rem;
  }

  .text_99 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 10.94rem;
  }

  .text_100 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 10.91rem;
  }

  .text_101 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 10.91rem;
  }

  .text_102 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 10.78rem;
  }

  .text_103 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_104 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_105 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_106 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_107 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_108 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_109 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_110 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_111 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_112 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_113 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.47rem;
    position: absolute;
    left: 1.5rem;
    bottom: 8.53rem;
  }

  .text_114 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    bottom: 8.47rem;
  }

  .text_115 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    bottom: 8.47rem;
  }

  .text_116 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    bottom: 8.47rem;
  }

  .text_117 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    bottom: 8.47rem;
  }

  .text_118 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    bottom: 8.69rem;
  }

  .text_119 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    bottom: 8.53rem;
  }

  .text_120 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    bottom: 8.53rem;
  }

  .text_121 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    bottom: 8.53rem;
  }

  .text_122 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    bottom: 8.47rem;
  }

  .text_123 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    left: 1.5rem;
    bottom: 6.03rem;
  }

  .text_124 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    bottom: 5.97rem;
  }

  .text_125 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    bottom: 5.97rem;
  }

  .text_126 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    bottom: 5.97rem;
  }

  .text_127 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    bottom: 5.97rem;
  }

  .text_128 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    bottom: 6.19rem;
  }

  .text_129 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    bottom: 6.03rem;
  }

  .text_130 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    bottom: 6.03rem;
  }

  .text_131 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    bottom: 6.03rem;
  }

  .text_132 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    bottom: 5.97rem;
  }

  .group_30 {
    margin-left: 0.31rem;
    overflow: hidden;
  }

  .group_31 {
    flex-shrink: 0;
  }

  .text_133 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.75rem;
  }

  .image_16 {
    flex-shrink: 0;
    border-radius: 0.13rem;
    width: 1.88rem;
    height: 1.88rem;
  }

  .image_17 {
    margin-left: 1.03rem;
  }

  .text-wrapper_4 {
    margin-left: 0.63rem;
    padding: 0.66rem 0 0.63rem;
    flex-shrink: 0;
    background-color: #0074ff;
    border-radius: 0.13rem;
    width: 1.88rem;
    height: 1.88rem;
  }

  .text_134 {
    color: #ffffff;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.59rem;
  }

  .text-wrapper_5 {
    margin-left: 0.63rem;
    padding: 0.66rem 0 0.63rem;
    flex-shrink: 0;
    background-color: #f4f4f5;
    border-radius: 0.13rem;
    width: 1.88rem;
    height: 1.88rem;
  }

  .text_135 {
    color: #000000;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .image_18 {
    margin-left: 0.63rem;
  }

  .space-x-4>*:not(:first-child) {
    margin-left: 0.25rem;
  }

  .group_32 {
    flex-shrink: 0;
    overflow: hidden;
    width: 6.81rem;
    height: 1.88rem;
  }

  .text_136 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.75rem;
  }

  .text-wrapper_6 {
    padding: 0.63rem 0 0.66rem;
    flex-shrink: 0;
    border-radius: 0.19rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #c0c4cc;
  }

  .text_137 {
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.53rem;
  }

  .text_138 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.72rem;
  }
</style>
